import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./components/manager/home/Home";
import Dashboard from "./components/manager/dashboard/Dashboard";
import Welcome from "./components/customer/pages/welcome/Welcome";
import Layout from "./components/customer/Layout";
import Register from "./components/customer/pages/register/Register";
import Gift from "./components/customer/pages/gift/Gift";
import Congrats from "./components/customer/pages/congrats/Congrats";
import Cashier from "./components/cashier/dashboard/Cashier";
import Login from "./components/cashier/login/Login";
import Stores from "./components/manager/store/Stores";
import CustomerPrivateRoute from "./protected/CustomerPrivateRoute";
import AdminPrivateRoute from "./protected/AdminPrivateRoute";
import CashierPrivateRoute from "./protected/CashierPrivateRoute";

import ManagerLogin from "./components/manager/login/ManagerLogin";
import Redeem from "./components/cashier/redeem/Redeem";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import "./App.css";
import ForgetPassword from "./components/manager/login/forgetPassword/ForgetPassword";
import CashierChangePassword from "./components/cashier/changePassowrd/CashierChangePassword";
import ManagerChangePassword from "./components/manager/login/changePassword/ManagerChangePassword";
import AddPoints from "./components/cashier/addpoints/AddPoints";
import styled from "styled-components";
import MerchantPrivateRoute from "./protected/MerchantPrivateRoute";
import NotFound from "./components/notfound/NotFound";

function App() {
  const OTPaccepted = useSelector(
    (state) => state.green.customer.info.acceptedOTP
  );
  const verified = useSelector((state) => state.green.customer.info.verify);
  const discount = useSelector(
    (state) => state.green.customer.voucher.discount
  );
  const isExisted = useSelector((state) => state.green.customer.info.isExist);
  const barcode = useSelector((state) => state.green.customer.voucher.barcode);

  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  });

  const cashierToken = useSelector((state) => state.green.cashier.login.token);
  const adminToken = useSelector((state) => state.green.admin.login.token);

  const merchants = ["greenCafe", "odonuts"];

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/:merchant/welcome" />}
          ></Route>
          <Route path="/*" element={<Navigate to="/404" />}></Route>
          <Route
            path=":merchant/welcome"
            element={
              <MerchantPrivateRoute>
                <Layout customerelement={<Welcome />} />
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route
            path=":merchant/balance"
            element={
              <MerchantPrivateRoute>
                <CustomerPrivateRoute condition={verified}>
                  <Layout customerelement={<Gift />} />
                </CustomerPrivateRoute>
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route
            path=":merchant/register"
            element={
              <MerchantPrivateRoute>
                <CustomerPrivateRoute
                  condition={
                    isExisted === false && discount !== "" ? true : false
                  }
                >
                  <Layout customerelement={<Register />} />
                </CustomerPrivateRoute>
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route
            path=":merchant/congrats"
            element={
              <MerchantPrivateRoute>
                <CustomerPrivateRoute
                  condition={barcode !== "" && discount !== "" ? true : false}
                >
                  <Layout customerelement={<Congrats />} />
                </CustomerPrivateRoute>
              </MerchantPrivateRoute>
            }
          ></Route>

          <Route
            path=":merchant/manager/dashboard"
            element={
              <MerchantPrivateRoute>
                <AdminPrivateRoute condition={adminToken}>
                  <Home />
                </AdminPrivateRoute>
              </MerchantPrivateRoute>
            }
          >
            <Route
              path="main"
              element={
                <MerchantPrivateRoute>
                  <AdminPrivateRoute condition={adminToken}>
                    <Dashboard />
                  </AdminPrivateRoute>
                </MerchantPrivateRoute>
              }
            ></Route>
            <Route
              path="stores"
              element={
                <MerchantPrivateRoute>
                  <AdminPrivateRoute condition={adminToken}>
                    <Stores />
                  </AdminPrivateRoute>
                </MerchantPrivateRoute>
              }
            ></Route>
          </Route>
          <Route
            path=":merchant/manager/login"
            element={
              <MerchantPrivateRoute>
                <ManagerLogin />
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route
            path=":merchant/manager/forget-password"
            element={
              <MerchantPrivateRoute>
                <ForgetPassword />
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route
            path=":merchant/manager/change-password"
            element={
              <MerchantPrivateRoute>
                <ManagerChangePassword />
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route
            path=":merchant/cashier/dashboard"
            element={
              <MerchantPrivateRoute>
                <CashierPrivateRoute condition={cashierToken}>
                  <Cashier />
                </CashierPrivateRoute>
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route
            path=":merchant/cashier/redeem"
            element={
              <MerchantPrivateRoute>
                <CashierPrivateRoute condition={cashierToken}>
                  <Redeem />
                </CashierPrivateRoute>
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route
            path={`:merchant/cashier/login`}
            element={
              <MerchantPrivateRoute>
                <Login />
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route
            path={`:merchant/cashier/change-password`}
            element={
              <MerchantPrivateRoute>
                <CashierPrivateRoute condition={cashierToken}>
                  <CashierChangePassword />
                </CashierPrivateRoute>
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route
            path=":merchant/cashier/addpoints"
            element={
              <MerchantPrivateRoute>
                <CashierPrivateRoute condition={cashierToken}>
                  <AddPoints />
                </CashierPrivateRoute>
              </MerchantPrivateRoute>
            }
          ></Route>
          <Route path="/404" element={<NotFound />}></Route>
        </Routes>
      </Router>
      {lang === "ar" ? (
        <ToastContainer
          rtl
          className="toast-style"
          pauseOnFocusLoss={false}
          autoClose={8000}
        />
      ) : (
        <ToastContainer
          className="toast-style"
          pauseOnFocusLoss={false}
          autoClose={8000}
        />
      )}
    </div>
  );
}

export default App;
