import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Cashiers from "./cashiers/Cashiers";
import Branches from "./branches/Branches";
import { useSelector } from "react-redux";
import styles from "./Stores.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { compare, decrypt } from "n-krypta";

export default function Stores() {
  const [cashiersmodal, setCashiersmodal] = useState(false);
  const [branchesmodal, setBranchesmodal] = useState(false);

  const navigate = useNavigate();

  const key = "ShareeCoin_1234567";

  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.admin.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
    }
  }, [storedMerchant]);





  return (
    <div className={`${styles.stores} p-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="py-4">
              <h4 className={styles.merchantTitle}>Green Cafe</h4>
              <p>Food and Beverage</p>
            </div>
          </Col>
          <div className={`${styles.storeContent} p-3`}>
            <Container>
              <Row>
                <Col xs={12} sm={8} md={9} lg={10}>
                  <h4>Stores</h4>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className={`${styles.storeBtnCotentRow} px-0 px-md-5`}>
                <Col xs={12} sm={12} md={6}>
                  <div
                    className={`${styles.storeBtnContent}`}
                    onClick={() => {
                      setBranchesmodal(true);
                    }}
                  >
                    <div className={`${styles.storeIcon} text-center`}>
                      <img
                        src={require("../../../imgs/Branches.png")}
                        alt="branches-iconn"
                      />
                    </div>
                    <div className={`${styles.storeTitleBtn} text-center`}>
                      Branches
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <div
                    className={`${styles.storeBtnContent}`}
                    onClick={() => {
                      setCashiersmodal(true);
                    }}
                  >
                    <div className={`${styles.storeIcon} text-center`}>
                      <img
                        src={require("../../../imgs/CashierMach.png")}
                        alt="cashiers-iconn"
                      />
                    </div>
                    <div className={`${styles.storeTitleBtn} text-center`}>
                      Cashiers
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
      <Cashiers show={cashiersmodal} onHide={() => setCashiersmodal(false)} />
      <Branches show={branchesmodal} onHide={() => setBranchesmodal(false)} />
    </div>
  );
}
