import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import styles from "./Branches.module.scss";
import AddBranch from "./addBranch/AddBranch";
import EditBranch from "./editBranch/EditBranch";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DelBranch from "./delBranch/DelBranch";
import { compare, decrypt } from "n-krypta";

export default function Branches(props) {
  const [addbranch, setAddbranch] = useState(false);
  const [editbranch, setEditbranch] = useState(false);
  const [delbranch, setDelbranch] = useState(false);
  const [branches, setBranches] = useState([]);

  const [branchId, setBranchId] = useState("");
  const [govId, setGovId] = useState("");
  const [cityId, setCityId] = useState("");
  const [branchName, setBranchName] = useState("");

  const [delBranchName, setDelBranchName] = useState("");

  const { t, i18n } = useTranslation();

  const merchantNamee = useParams().merchant;

  useEffect(() => {
    axios
      .get("https://hook.eu1.make.com/op9b65jo99zuj9lfs0eqxg7mg23ezw64", {
        params: {
          merchantName:
            merchantNamee === "greenCafe" ? "Green Cafe" : "Odonuts",
        },
      })
      .then((res) => {
        setBranches(res.data["Branch List"]);
      });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.green.admin.login.token);

  const key = "ShareeCoin_1234567";

  const storedMerchant = useSelector(
    (state) => state.green.admin.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
    }
  }, [storedMerchant]);

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={true}
      >
        <Modal.Header closeButton className={`${styles.branchsHeader}`}>
          <Modal.Title id="contained-modal-title-vcenter">Branches</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${styles.branchsBody}`}>
          <Container>
            <Row>
              <Col xs={12} md={{ span: 6, offset: 3 }}>
                <div className="d-grid">
                  <Button
                    variant="success"
                    size="lg"
                    className={`${styles.addBranchBtn}`}
                    onClick={() => {
                      setAddbranch(true);
                    }}
                  >
                    Add Branch
                  </Button>
                </div>
              </Col>
              <Col
                xs={12}
                md={{ span: 10, offset: 1 }}
                lg={{ span: 8, offset: 2 }}
              >
                <div className={`${styles.bgWhite} p-3 mt-3`}>
                  <Container>
                    <Row>
                      <Col xs={4} className={`${styles.branchName}`}>
                        Branches:
                      </Col>
                      <Col xs={8}>
                        {branches.map((store, i) => {
                          return (
                            <div
                              key={i}
                              className={`${styles.branchElement} px-2 py-1 d-flex justify-content-between align-items-center mb-2`}
                            >
                              <span>
                                {store["Branch Name Ar"]} -{" "}
                                {store["Governorate Name Ar"]}
                              </span>
                              <div>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="mx-1"
                                  onClick={() => {
                                    setEditbranch(true);
                                    setBranchId(store["BranchID"]);
                                    setBranchName(store["Branch Name Ar"]);
                                    setCityId(store["CityID"]);
                                    setGovId(store["GovernorateID"]);
                                  }}
                                >
                                  <FiEdit />
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="mx-1"
                                  onClick={() => {
                                    setDelbranch(true);
                                    setDelBranchName(store["Branch Name Ar"]);
                                  }}
                                >
                                  <BsTrash />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <AddBranch
        show={addbranch}
        onHide={() => {
          setAddbranch(false);
        }}
      />
      <EditBranch
        show={editbranch}
        onHide={() => {
          setEditbranch(false);
        }}
        branchid={branchId}
        govid={govId}
        cityid={cityId}
        branchname={branchName}
      />
      <DelBranch
        show={delbranch}
        onHide={() => {
          setDelbranch(false);
        }}
        branchname={delBranchName}
      />
    </div>
  );
}
