import React, { useEffect } from "react";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { FaMobile, FaLock } from "react-icons/fa";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast, Zoom } from "react-toastify";
import {
  adminLogin,
  clearResults,
  handleMerchantName,
} from "../../../redux/features/adminSlice";
import successMessage from "../../messages/Successful";
import failedMessage from "../../messages/Failed";
import styles from "./ManagerLogin.module.scss";
import { decrypt, encrypt } from "n-krypta";

export default function ManagerLogin() {
  const managerLoginValidtion = yup.object().shape({
    mobileNumber: yup.string().required("Mobile number is required"),
    password: yup.string().required("Password is required"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(clearResults());
  }, []);

  const merchantNamee = useParams().merchant;

  const key = "ShareeCoin_1234567";

  return (
    <div className={`${styles.ManagerLoginContent} p-3`} dir="ltr">
      <h5 className="mb-4 pt-5 px-5">Welcome to Green Cafe Rewards</h5>
      <Container>
        <Row
          className={`${styles.loginCol} d-flex justify-content-center align-items-center`}
        >
          <Col xs={12} md={4} className="text-center">
            {merchantNamee === "greenCafe" && (
              <img
                src={require("../../../imgs/green-white-logo.png")}
                alt="green-logo"
                className={`${styles.greenLogo}`}
              />
            )}
            {merchantNamee === "odonuts" && (
              <img
                src={require("../../../imgs/3213.png")}
                alt="green-logo"
                className={`${styles.greenLogo}`}
              />
            )}
          </Col>
          <Col xs={12} md={{ span: 6, offset: 1 }}>
            <h5 className="text-center mb-3">USER LOGIN</h5>
            <Formik
              initialValues={{ mobileNumber: "", password: "" }}
              onSubmit={(values) => {
                const loadReq = toast.loading("Loading...", {
                  transition: Zoom,
                  position:
                    i18n.language === "ar" ? "bottom-right" : "bottom-left",
                });
                dispatch(
                  adminLogin({
                    mobileNumber: values.mobileNumber,
                    password: values.password,
                    merchantName: merchantNamee,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    navigate(`/${merchantNamee}/manager/dashboard/main`);
                    dispatch(
                      handleMerchantName({
                        merchantName: encrypt(merchantNamee, key),
                      })
                    );
                    successMessage(
                      res.payload.data.message,
                      res.payload.data["messageAr"],
                      loadReq
                    );
                  } else {
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data["errorAr"],
                      loadReq
                    );
                  }
                });
              }}
              validationSchema={managerLoginValidtion}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} className="mb-3">
                        <InputGroup className={`${styles.loginInput} px-2`}>
                          <InputGroup.Text
                            className={`${styles.loginInputIcon}`}
                          >
                            <FaMobile />
                          </InputGroup.Text>
                          <Form.Control
                            type="tel"
                            maxLength={11}
                            placeholder="Phone Number"
                            className={`${styles.loginInputField}`}
                            id="mobileNumber"
                            name="mobileNumber"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.mobileNumber}
                          />
                        </InputGroup>
                        {errors.mobileNumber && touched.mobileNumber && (
                          <Form.Text className="text-error-green">
                            {errors.mobileNumber}
                          </Form.Text>
                        )}
                      </Col>
                      <Col xs={12} className="mb-3">
                        <InputGroup className={`${styles.loginInput} px-2`}>
                          <InputGroup.Text
                            className={`${styles.loginInputIcon}`}
                          >
                            <FaLock />
                          </InputGroup.Text>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            className={`${styles.loginInputField}`}
                            id="password"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                          />
                        </InputGroup>
                        {errors.password && touched.password && (
                          <Form.Text className="text-error-green">
                            {errors.password}
                          </Form.Text>
                        )}
                      </Col>
                      <div className="text-center">
                        <Button
                          variant="danger"
                          type="submit"
                          size="sm"
                          className={`${styles.loginBtn} mt-1`}
                          disabled={isSubmitting}
                        >
                          LOGIN
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
      <div className={`${styles.poweredLogoContent} px-5`}>
        <img
          src={require("../../../imgs/Powered by white.png")}
          alt="powered-logo"
        />
      </div>
    </div>
  );
}
