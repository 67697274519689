import React, { useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCustomerData,
  sendOTP,
} from "../../../../redux/features/customerSlice";
import { useNavigate, useParams } from "react-router-dom";
import Otp from "../gift/otp/Otp";
import RegisterContent from "./RegisterContent";
import "./Register.css";
import { compare, decrypt } from "n-krypta";

export default function Register() {
  const [govs, setGovs] = useState([]);
  const [cities, setCities] = useState([]);
  const [govID, setGovID] = useState(null);

  const { t, i18n } = useTranslation();

  const registerValidation = yup.object().shape({
    firstName: yup
      .string()
      .required("firstNameReq")
      .matches(
        /^((?![0-9.,!?:;_|+\-*\\/=%°@&#§$"'`¨^ˇ()\[\]<>{}])[\S])+$/gm,
        "nameNotValid"
      )
      .matches(/^[a-zA-Z]*$/, "nameinEnglish"),
    lastName: yup
      .string()
      .required("lastNameReq")
      .matches(
        /^((?![0-9.,!?:;_|+\-*\\/=%°@&#§$"'`¨^ˇ()\[\]<>{}])[\S])+$/gm,
        "nameNotValid"
      )
      .matches(/^[a-zA-Z]*$/, "nameinEnglish"),
    email: yup.string().email("emailNotValid").required("emailReq"),
    gender: yup.string().required("genderReq"),
    governorate: yup.string().required("goverReq"),
    city: yup.string().required("cityReq"),
  });

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        setGovs(res.data["Governates List"]);
      });

    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: govID,
        Function: "ListCites",
      })
      .then((res) => {
        setCities(res.data["List of Cities"]);
      });
  }, [govID]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const phoneNumber = useSelector(
    (state) => state.green.customer.info.phoneNumber
  );
  const discountValue = useSelector(
    (state) => state.green.customer.voucher.discount
  );

  const [showOTP, setShowOTP] = useState(false);

  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.customer.merchantName
  );
  const key = "ShareeCoin_1234567";

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/welcome`);
    }
  }, [storedMerchant]);

  return (
    <RegisterContent merchant={merchantNamee}>
      <div>
        {merchantNamee === "greenCafe" && (
          <Container>
            <Row>
              <Col xs={12}>
                <div className="throwPicContent">
                  <img
                    src={require("../../../../imgs/throw.png")}
                    alt="throw-pic"
                    className="throwPic img-responsive mb-4"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        )}

        <Container>
          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <div className="registerContent p-4">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    gender: "",
                    governorate: "",
                    city: "",
                  }}
                  onSubmit={(values) => {
                    dispatch(
                      handleCustomerData({
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        gender: values.gender,
                        governorate: values.governorate,
                        city: values.city,
                      })
                    );
                    const loadReq = toast.loading(t("loading"), {
                      transition: Zoom,
                      position:
                        i18n.language === "ar" ? "bottom-right" : "bottom-left",
                    });
                    dispatch(
                      sendOTP({
                        phoneNumber,
                      })
                    ).then((res) => {
                      if (res.payload.data.statusCode === 200) {
                        successMessage(
                          res.payload.data.message,
                          res.payload.data["messageAr"],
                          loadReq
                        );
                        setShowOTP(true);
                      } else {
                        failedMessage(
                          res.payload.data.error,
                          res.payload.data["errorAr"],
                          loadReq
                        );
                      }
                    });
                  }}
                  validationSchema={registerValidation}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group as={Row} className="inputGroup p-3 mb-3">
                        <Form.Label column sm={4} className="inputLabel">
                          {t("name")}
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            type="text"
                            placeholder={t("firstName")}
                            className="inputField mb-2 mb-md-2"
                            name="firstName"
                            id="firstName"
                            onChange={handleChange}
                            value={values.firstName}
                          />
                          {errors.firstName && touched.firstName && (
                            <Form.Text className="text-error">
                              {t(errors.firstName)}
                            </Form.Text>
                          )}
                        </Col>
                        <Col sm={4}>
                          <Form.Control
                            type="text"
                            placeholder={t("lastName")}
                            className="inputField"
                            name="lastName"
                            id="lastName"
                            onChange={handleChange}
                            value={values.lastName}
                          />
                          {errors.lastName && touched.lastName && (
                            <Form.Text className="text-error">
                              {t(errors.lastName)}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="inputGroup p-3 mb-3">
                        <Form.Label column sm={4} className="inputLabel">
                          {t("gender")}
                        </Form.Label>
                        <Col sm={6}>
                          <Container>
                            <Row>
                              <Col className="mb-2 mb-lg-0">
                                <input
                                  hidden
                                  type="radio"
                                  name="gender"
                                  id="male"
                                  className="genderInput"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setFieldValue("gender", "male");
                                    }
                                  }}
                                />
                                <label
                                  htmlFor="male"
                                  className="genderLabel py-1"
                                >
                                  <span>{t("male")}</span>
                                  <img
                                    src={require("../../../../imgs/Male.png")}
                                    alt="male-pic"
                                    className="genderIcon"
                                  />
                                </label>
                              </Col>
                              <Col>
                                <input
                                  hidden
                                  type="radio"
                                  name="gender"
                                  id="female"
                                  className="genderInput"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setFieldValue("gender", "female");
                                    }
                                  }}
                                />
                                <label
                                  htmlFor="female"
                                  className="genderLabel py-1"
                                >
                                  <span>{t("female")}</span>
                                  <img
                                    src={require("../../../../imgs/Female.png")}
                                    alt="female-pic"
                                    className="genderIcon"
                                  />
                                </label>
                              </Col>
                              {errors.gender && touched.gender && (
                                <Form.Text className="text-error">
                                  {t(errors.gender)}
                                </Form.Text>
                              )}
                            </Row>
                          </Container>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="inputGroup p-3 mb-3">
                        <Form.Label column sm={4} className="inputLabel">
                          {t("email")}
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="text"
                            placeholder={t("email")}
                            className="inputField"
                            name="email"
                            id="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                          {errors.email && touched.email && (
                            <Form.Text className="text-error">
                              {t(errors.email)}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="inputGroup p-3">
                        <Form.Label column sm={12} className="inputLabel">
                          {t("addressDetails")}:
                        </Form.Label>
                        <Col sm={{ span: 8, offset: 3 }}>
                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4} className="inputLabel">
                              {t("governorate")}:
                            </Form.Label>
                            <Col sm={8}>
                              <Form.Select
                                className="customerInput"
                                name="governorate"
                                onChange={(e) => {
                                  setFieldValue("governorate", e.target.value);
                                  setGovID(e.target.value);
                                }}
                                value={values.governorate}
                              >
                                <option value="">Select</option>
                                {govs.map((gover) => {
                                  return (
                                    <option
                                      value={gover.GovernateID}
                                      key={Math.random() * 10000}
                                    >
                                      {i18n.language === "en"
                                        ? gover["governorate_name_en"]
                                        : gover["governorate_name_ar"]}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              {errors.governorate && touched.governorate && (
                                <Form.Text className="text-error">
                                  {t(errors.governorate)}
                                </Form.Text>
                              )}
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm={4} className="inputLabell">
                              {t("district")}:
                            </Form.Label>
                            <Col sm={8}>
                              <Form.Select
                                className="customerInput"
                                name="city"
                                onChange={(e) => {
                                  setFieldValue("city", e.target.value);
                                }}
                                value={values.city}
                              >
                                <option value="">Select</option>
                                {cities.map((city) => {
                                  return (
                                    <option
                                      value={city.cityID}
                                      key={Math.random() * 10000}
                                    >
                                      {i18n.language === "en"
                                        ? city["city_name_en"]
                                        : city["city_name_Ar"]}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              {errors.city && touched.city && (
                                <Form.Text className="text-error">
                                  {t(errors.city)}
                                </Form.Text>
                              )}
                            </Col>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                      <div className="text-center">
                        <Button
                          variant="success"
                          size="md"
                          className="proceedBtn mt-3"
                          type="submit"
                        >
                          {t("register")}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
        <Otp
          show={showOTP}
          onHide={() => {
            setShowOTP(false);
          }}
        />
      </div>
    </RegisterContent>
  );
}
