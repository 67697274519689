import React from "react";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { MdOutlineMobileFriendly } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../../../messages/Successful";
import { createVoucher } from "../../../../../redux/features/customerSlice";
import failedMessage from "../../../../messages/Failed";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import "./Otp.css";

export default function Otp(props) {
  const otpValidation = yup.object().shape({
    firstDigit: yup.string().required("كود OTP مطلوب"),
    secondDigit: yup.string().required("كود OTP مطلوب"),
    thirdDigit: yup.string().required("كود OTP مطلوب"),
    fourthDigit: yup.string().required("كود OTP مطلوب"),
    fifthDigit: yup.string().required("كود OTP مطلوب"),
    sixthDigit: yup.string().required("كود OTP مطلوب"),
  });

  const handleClickotp = (first, last) => {
    if (document.getElementById(first).value !== "") {
      document.getElementById(last).focus();
    }
  };

  const firstName = useSelector((state) => state.green.customer.info.firstName);
  const lastName = useSelector((state) => state.green.customer.info.lastName);
  const email = useSelector((state) => state.green.customer.info.email);
  const gender = useSelector((state) => state.green.customer.info.gender);
  const govern = useSelector((state) => state.green.customer.info.govern);
  const city = useSelector((state) => state.green.customer.info.city);
  const discount = useSelector(
    (state) => state.green.customer.voucher.discount
  );

  const phoneNumber = useSelector(
    (state) => state.green.customer.info.phoneNumber
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const StyledOtpContent = styled.div`
    .otpInput {
      width: 100%;
      border: 2px solid #88c599 !important;
      border-radius: 10px;
      outline: none;
      box-shadow: none;
      text-align: center;
      font-weight: 700;
      font-family: "Montserrat", "IBMPlexSansArabic" !important;
    }
    .nextBtn {
      border-radius: 20px;
      font-weight: 700;
      font-size: 0.9rem;
      padding: 10px 25px;
    }
  `;

  const merchantNamee = useParams().merchant;

  return (
    <StyledOtpContent merchant={merchantNamee}>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="ModalBodyOTP">
          <Formik
            initialValues={{
              firstDigit: "",
              secondDigit: "",
              thirdDigit: "",
              fourthDigit: "",
              fifthDigit: "",
              sixthDigit: "",
            }}
            onSubmit={(values, { resetForm }) => {
              const loadReq = toast.loading(t("loading"), {
                transition: Zoom,
                position:
                  i18n.language === "ar" ? "bottom-right" : "bottom-left",
              });
              var otpText = "";
              otpText += values.firstDigit;
              otpText += values.secondDigit;
              otpText += values.thirdDigit;
              otpText += values.fourthDigit;
              otpText += values.fifthDigit;
              otpText += values.sixthDigit;
              dispatch(
                createVoucher({
                  phoneNumber,
                  firstName,
                  lastName,
                  email,
                  governID: govern,
                  cityID: city,
                  gender,
                  discount,
                  OTP: otpText,
                  merchantName: merchantNamee,
                  voucherName:
                    merchantNamee === "greenCafe"
                      ? "Green Cafe EGP"
                      : merchantNamee === "odonuts"
                      ? "Odonuts EGP"
                      : "",
                })
              ).then((res) => {
                if (res.payload.data.success === true) {
                  successMessage(
                    res.payload.data.message,
                    res.payload.data["messageAr"],
                    loadReq
                  );
                  navigate(`/${merchantNamee}/congrats`);
                } else {
                  failedMessage(
                    res.payload.data.error,
                    res.payload.data["errorAr"],
                    loadReq
                  );
                }
              });
            }}
            validationSchema={otpValidation}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="px-4">
                <Row>
                  <Col xs={12} className="mb-3">
                    <MdOutlineMobileFriendly className="mx-1" />
                    {t("OTP")} :
                  </Col>
                </Row>
                <Form.Group
                  dir="ltr"
                  as={Row}
                  className="digitsParent mb-3 p-3 d-flex justify-content-center"
                >
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`otpInput ${
                        errors.firstDigit && touched.firstDigit && "errorInput"
                      }`}
                      placeholder=""
                      size="sm"
                      name="firstDigit"
                      id="firstDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("firstDigit", "secondDigit");
                      }}
                      onChange={handleChange}
                      value={values.firstDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`otpInput ${
                        errors.secondDigit &&
                        touched.secondDigit &&
                        "errorInput"
                      }`}
                      placeholder=""
                      size="sm"
                      name="secondDigit"
                      id="secondDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("secondDigit", "thirdDigit");
                      }}
                      onChange={handleChange}
                      value={values.secondDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`otpInput ${
                        errors.thirdDigit && touched.thirdDigit && "errorInput"
                      }`}
                      placeholder=""
                      size="sm"
                      name="thirdDigit"
                      id="thirdDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("thirdDigit", "fourthDigit");
                      }}
                      onChange={handleChange}
                      value={values.thirdDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`otpInput ${
                        errors.fourthDigit &&
                        touched.fourthDigit &&
                        "errorInput"
                      }`}
                      placeholder=""
                      size="sm"
                      name="fourthDigit"
                      id="fourthDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("fourthDigit", "fifthDigit");
                      }}
                      onChange={handleChange}
                      value={values.fourthDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`otpInput ${
                        errors.fifthDigit && touched.fifthDigit && "errorInput"
                      }`}
                      placeholder=""
                      size="sm"
                      name="fifthDigit"
                      id="fifthDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("fifthDigit", "sixthDigit");
                      }}
                      onChange={handleChange}
                      value={values.fifthDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`otpInput ${
                        errors.sixthDigit && touched.sixthDigit && "errorInput"
                      }`}
                      placeholder=""
                      size="sm"
                      name="sixthDigit"
                      id="sixthDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onChange={handleChange}
                      value={values.sixthDigit}
                    />
                  </Col>
                </Form.Group>

                <div className="d-grid">
                  <Button variant="success" className="nextBtn" type="submit">
                    {t("proceed")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </StyledOtpContent>
  );
}
