import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { AiOutlineFileSearch } from "react-icons/ai";
import { TiGroup } from "react-icons/ti";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import styles from "./Dashboard.module.scss";
import QrModal from "./qr/QrModal";
import CustomersModal from "./customers/CustomersModal";
import CashierReport from "./reports/CashierReport";

export default function Dashboard() {
  const [showQrModal, setShowQrModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  return (
    <div className={`${styles.dashboard} p-3`} dir="ltr">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="py-4">
              <h4 className={styles.merchantTitle}>Green Cafe</h4>
              <p>Food and Beverage</p>
            </div>
          </Col>
          <div className={`${styles.bankContent} p-3`}>
            <Container>
              <Row>
                <Col xs={12} sm={8} md={9} lg={10}>
                  <h4>Can Bank Points</h4>
                </Col>
                <Col xs={12} sm={4} md={3} lg={2}>
                  <div className="d-grid">
                    <Button
                      variant="light"
                      size="md"
                      className={`${styles.qrBtn}`}
                      onClick={() => {
                        setShowQrModal(true);
                      }}
                    >
                      <MdOutlineQrCodeScanner className="mx-2" />
                      QR
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className={`${styles.homeBtnCotentRow} px-0 px-md-5`}>
                <Col>
                  <div
                    className={`${styles.homeBtnContent}`}
                    onClick={() => {
                      setShowReportModal(true);
                    }}
                  >
                    <div className={`${styles.homeIcon} text-center`}>
                      <AiOutlineFileSearch />
                    </div>
                    <div className={`${styles.homeTitleBtn} text-center`}>
                      Reports
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className={`${styles.homeBtnContent}`}
                    onClick={() => {
                      setShowCustomerModal(true);
                    }}
                  >
                    <div className={`${styles.homeIcon} text-center`}>
                      <TiGroup />
                    </div>
                    <div className={`${styles.homeTitleBtn} text-center`}>
                      Customers
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
      <QrModal
        show={showQrModal}
        onHide={() => {
          setShowQrModal(false);
        }}
      />
      <CustomersModal
        show={showCustomerModal}
        onHide={() => {
          setShowCustomerModal(false);
        }}
      />
      <CashierReport
        show={showReportModal}
        onHide={() => {
          setShowReportModal(false);
        }}
      />
    </div>
  );
}
