import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const sendOTP = createAsyncThunk(
  "customer/send",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/sendOTP",
        {
          phonenumber: info["phoneNumber"],
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const getBalance = createAsyncThunk(
  "customer/accept",
  async (info, { rejectWithValue }) => {
    try {
      const ress = await axios.get(
        "https://evouchers.shareecoin.com/coinsBalance",
        {
          params: {
            phonenumber: info.phoneNumber,
            merchant: info.merchantName,
          },
        }
      );
      return ress;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createVoucher = createAsyncThunk(
  "customer/create/voucher",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/createVoucherWZOTP",
        {
          phoneNumber: data["phoneNumber"],
          firstName: data["firstName"],
          lastName: data["lastName"],
          email: data["email"],
          governmentID: data["governID"],
          cityID: data["cityID"],
          gender: data["gender"],
          merchantName: data["merchantName"],
          voucherName: data["voucherName"],
          EGP: data["discount"],
          otp: data["OTP"],
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {
  info: {
    OTP: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    govern: "",
    city: "",
    discountValue: null,
    isExist: false,
    acceptedOTP: false,
    verify: false,
  },
  balance: {
    points: 0,
    egp: 0,
  },
  voucher: {
    barcode: "",
    discount: "",
  },
  merchantName: "",
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    handleVoucherDiscount: (state, action) => {
      state.voucher.discount = action.payload.discountValue;
    },
    clearResults: (state, action) => {
      state.info.phoneNumber = "";
      state.info.firstName = "";
      state.info.lastName = "";
      state.info.gender = "";
      state.info.email = "";
      state.info.governID = "";
      state.info.cityID = "";
      state.info.isExist = false;
      state.info.acceptedOTP = false;
      state.info.verify = false;
      state.info.OTP = "";
      state.info.discountValue = null;
      state.balance.points = 0;
      state.balance.egp = 0;
      state.voucher.barcode = "";
      state.voucher.discount = "";
      state.merchantName="";
    },
    handleCustomerData: (state, action) => {
      state.info.firstName = action.payload.firstName;
      state.info.lastName = action.payload.lastName;
      state.info.email = action.payload.email;
      state.info.gender = action.payload.gender;
      state.info.govern = action.payload.governorate;
      state.info.city = action.payload.city;
    },
    handleMerchantName: (state, action) => {
      state.merchantName = action.payload.merchantName;
    },
  },
  extraReducers: {
    [sendOTP.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.info.phoneNumber = action.meta.arg.phoneNumber;
      }
    },
    [getBalance.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.info.phoneNumber = action.meta.arg.phoneNumber;
        state.info.firstName = action.payload.data.FirstName;
        state.info.lastName = action.payload.data.LastName;
        state.info.gender = action.payload.data.gender;
        state.info.email = action.payload.data.email;
        state.info.isExist = action.payload.data.isExists;
        state.info.govern = action.payload.data.governmentID;
        state.info.city = action.payload.data.cityID;
        state.balance.points = action.payload.data.points;
        state.balance.egp = action.payload.data.EGP;
        state.info.verify = true;
      }
    },
    [createVoucher.fulfilled]: (state, action) => {
      if (action.payload.data.success === true) {
        state.voucher.barcode = action.payload.data.barcode64;
      }
    },
  },
});

export const { handleVoucherDiscount, clearResults, handleCustomerData,handleMerchantName } =
  customerSlice.actions;
export default customerSlice.reducer;
