import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast, Zoom } from "react-toastify";
import styles from "./Redeem.module.scss";
import successMessage from "../../messages/Successful";
import failedMessage from "../../messages/Failed";
import ChangeLang from "../../changeLang/ChangeLang";
import {
  burnVouch,
  clearRedeem,
  redeemVoucher,
  resetScan,
} from "../../../redux/features/cashierSlice";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { BsUpcScan } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { compare, decrypt } from "n-krypta";
import ScanBarcode from "./scan/ScanBarcode";

export default function Redeem() {
  const token = useSelector((state) => state.green.cashier.login.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [activeStep, setActiveStep] = useState(false);

  const [showScanBarcode, setShowScanBarcode] = useState(false);

  const barcodeValidation = yup.object().shape({
    barcodeNum: yup.string().required("Bracode is required"),
  });
  const receiptValidation = yup.object().shape({
    receiptId: yup.string().required("Receipt number is required"),
  });

  const recordID = useSelector((state) => state.green.cashier.redeem.recordID);
  const airTableID = useSelector(
    (state) => state.green.cashier.redeem.airTableID
  );
  const cashierID = useSelector(
    (state) => state.green.cashier.redeem.cashierID
  );

  const branchID = useSelector((state) => state.green.cashier.redeem.branchID);

  const scannedCode = useSelector(
    (state) => state.green.cashier.redeem.scannedCode
  );

  const [discount, setDiscount] = useState(0);

  const key = "ShareeCoin_1234567";
  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.cashier.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/cashier/login`);
    }
  }, [storedMerchant]);

  return (
    <div className={`${styles.redeemContent} p-3 p-md-5`}>
      <Col xs={12} className="mb-4">
        <ChangeLang />
      </Col>
      <h4 className="mb-4 d-inline-block">{t("redeemGift")}</h4>
      <Button
        variant="success"
        size="sm"
        className={`${styles.homeBtn} mx-3`}
        onClick={() => {
          navigate(`/${decrypt(storedMerchant, key)}/cashier/dashboard`);
        }}
      >
        <FaHome />
      </Button>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <Container>
              <Row>
                <Col xs={12}>
                  <div className={`${styles.bgWhite} p-3 mb-3`}>
                    <h5>{t("firstStep")}</h5>
                    <Formik
                      initialValues={{
                        barcodeNum: scannedCode ? scannedCode : "",
                      }}
                      onSubmit={(values) => {
                        const loadReq = toast.loading("Loading...", {
                          transition: Zoom,
                          position:
                            i18n.language === "ar"
                              ? "bottom-right"
                              : "bottom-left",
                        });
                        dispatch(
                          redeemVoucher({
                            barcodeNum: values.barcodeNum,
                            token,
                          })
                        ).then((res) => {
                          if (res.payload.data.statusCode === 200) {
                            successMessage(
                              res.payload.data.message,
                              res.payload.data["messageAr"],
                              loadReq
                            );
                            setActiveStep(true);
                            setDiscount(res.payload.data.discountValue);
                            dispatch(resetScan());
                          } else if (res.payload.data.statusCode === 400) {
                            failedMessage(
                              res.payload.data.error,
                              res.payload.data["errorAr"],
                              loadReq
                            );
                          } else {
                            failedMessage(
                              res.payload.data.error,
                              res.payload.data["errorAr"],
                              loadReq
                            );
                          }
                        });
                      }}
                      validationSchema={barcodeValidation}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                      }) => {
                        return (
                          <Form onSubmit={handleSubmit}>
                            <Form.Group
                              as={Row}
                              className={`${styles.inputGroup} p-3`}
                            >
                              <Form.Label
                                column
                                sm={12}
                                className={`${styles.inputLabel}`}
                              >
                                {t("scanGiftCode")}:
                              </Form.Label>
                              <Col
                                xs={12}
                                sm={8}
                                md={9}
                                className={`${styles.bgGrey} px-3 py-2`}
                              >
                                <Form.Control
                                  type="text"
                                  className={`${styles.inputField}`}
                                  id="barcodeNum"
                                  name="barcodeNum"
                                  onChange={handleChange}
                                  value={values.barcodeNum}
                                />
                                {errors.barcodeNum && touched.barcodeNum && (
                                  <Form.Text className="text-error-green">
                                    {errors.barcodeNum}
                                  </Form.Text>
                                )}
                              </Col>
                              <Col xs={12} sm={4} md={3}>
                                <div className="d-grid">
                                  <Button
                                    variant="success"
                                    size="sm"
                                    className={`${styles.scanBtn} mt-2 mt-md-0`}
                                    onClick={() => {
                                      dispatch(resetScan());
                                      setShowScanBarcode(true);
                                    }}
                                  >
                                    <BsUpcScan />
                                  </Button>
                                </div>
                              </Col>
                            </Form.Group>
                            <div className="text-center">
                              <Button
                                variant="success"
                                size="sm"
                                className={`${styles.proceedBtn} mt-1`}
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {t("proceed")}
                              </Button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </Col>
                <Col xs={12}>
                  <div
                    className={`${styles.bgWhite} p-3 d-flex justify-content-center align-items-center`}
                  >
                    <div className={`${styles.giftContent}`}>
                      {t("giftt")}:{" "}
                      <span className="mx-3 d-block">
                        {discount} <span className={`${styles.unit}`}>EGP</span>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12} md={12} lg={6} className="mt-3 mt-md-0">
            <div className={`${styles.bgWhite} p-3 mb-3`}>
              <h5>{t("secondStep")}</h5>
              <Formik
                initialValues={{ receiptId: "" }}
                onSubmit={(values) => {
                  const loadReq = toast.loading("Loading...", {
                    transition: Zoom,
                    position:
                      i18n.language === "ar" ? "bottom-right" : "bottom-left",
                  });
                  dispatch(
                    burnVouch({
                      recordID,
                      airTableID,
                      receiptID: values.receiptId,
                      cashierID,
                      branchID,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      successMessage(
                        res.payload.data.message,
                        res.payload.data["messageAr"],
                        loadReq
                      );
                      dispatch(clearRedeem());
                      navigate(
                        `/${decrypt(storedMerchant, key)}/cashier/dashboard`
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data["errorAr"],
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={receiptValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group
                        as={Row}
                        className={`${styles.inputGroup} p-3`}
                      >
                        <Form.Label
                          column
                          sm={12}
                          className={`${styles.inputLabel}`}
                        >
                          {t("redeemNote")}
                        </Form.Label>
                        <Col xs={12} className={`${styles.bgGrey} px-3 py-2`}>
                          <Row>
                            <Col>
                              <Form.Control
                                type="text"
                                className={`${styles.inputField}`}
                                name="receiptId"
                                id="receiptId"
                                onChange={handleChange}
                                value={values.receiptId}
                                disabled={activeStep === true ? false : true}
                              />
                              {errors.receiptId && touched.receiptId && (
                                <Form.Text className="text-error-green">
                                  {errors.receiptId}
                                </Form.Text>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>
                      <div className="text-center">
                        <Button
                          variant="success"
                          size="sm"
                          className={`${styles.proceedBtn} mt-1`}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {t("redeem")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
      <ScanBarcode
        show={showScanBarcode}
        onHide={() => {
          setShowScanBarcode(false);
          window.location.reload();
        }}
      />
    </div>
  );
}
