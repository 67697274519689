import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./NotFound.module.scss";

export default function NotFound() {
  return (
    <div className={`${styles.notFoundContent}`}>
      <div className={`${styles.bgGrey} p-5`}>
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h4>404</h4>
              <p>Page Not Found</p>
            </Col>
            <Col xs={12} className="text-center">
              <img
                src={require("../../imgs/sad.png")}
                alt="sad-emoji"
                className={`${styles.sadFace}`}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
