import React, { useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Row, Container, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./AddPoints.module.scss";
import { useDispatch, useSelector } from "react-redux";
import successMessage from "../../messages/Successful";
import failedMessage from "../../messages/Failed";
import { addPoints } from "../../../redux/features/cashierSlice";
import { toast, Zoom } from "react-toastify";
import { compare, decrypt } from "n-krypta";
import { useTranslation } from "react-i18next";

export default function AddPoints() {
  const merchantName = useParams().merchant;
  const navigate = useNavigate();

  useEffect(() => {
    if (merchantName !== "odonuts") {
      navigate("/404");
    }
  }, []);

  const addPointsValidation = yup.object().shape({
    customerMobile: yup
      .string()
      .required("Customer mobile number is required")
      .matches(/^01[0125][0-9]{8}$/gm, "Customer mobile number is not valid"),
    receiptValue: yup.number().required("Receipt value is required"),
    orderNumber: yup.number().required("Order number is required"),
  });

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const key = "ShareeCoin_1234567";
  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.cashier.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/cashier/login`);
    }
  }, [storedMerchant]);

  const token = useSelector((state) => state.green.cashier.login.token);

  return (
    <div className={`${styles.addPointsContent} p-3 p-md-5`} dir="ltr">
      <h4 className={`${styles.addPointsTitle} px-4`}>Add Points</h4>
      <Formik
        initialValues={{
          customerMobile: "",
          receiptValue: "",
          orderNumber: "",
        }}
        onSubmit={(values) => {
          console.log(values);
          const loadReq = toast.loading("Loading...", {
            transition: Zoom,
            position: i18n.language === "ar" ? "bottom-right" : "bottom-left",
          });
          dispatch(
            addPoints({
              merchantName: decrypt(storedMerchant, key),
              receiptValue: values.receiptValue,
              orderNumber: values.orderNumber,
              customerMobile: values.customerMobile,
              token,
            })
          ).then((res) => {
            if (res.payload.data.statusCode === 200) {
              successMessage(
                res.payload.data.message,
                res.payload.data["messageAr"],
                loadReq
              );
            } else {
              failedMessage(
                res.payload.data.error,
                res.payload.data["errorAr"],
                loadReq
              );
            }
          });
        }}
        validationSchema={addPointsValidation}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  <Col sm={12} className={`${styles.bgWhite} p-4 mb-3`}>
                    <h4>Customer Data</h4>
                    <Container>
                      <Row>
                        <Col xs={12} sm={6}>
                          <Form.Group as={Row}>
                            <Form.Label
                              column
                              sm={12}
                              className={`${styles.addPointsLabel}`}
                            >
                              Customer mobile number
                            </Form.Label>
                            <Col
                              sm={8}
                              className={`${styles.bgGrey} px-3 py-2`}
                            >
                              <Form.Control
                                type="tel"
                                maxLength={11}
                                className={`${styles.addpointInput}`}
                                id="customerMobile"
                                name="customerMobile"
                                onKeyPress={(e) => {
                                  var char = String.fromCharCode(e.which);
                                  if (!/[0-9]/.test(char)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={handleChange}
                                value={values.customerMobile}
                              />
                            </Col>
                            {errors.customerMobile &&
                              touched.customerMobile && (
                                <Form.Text className="text-error-green">
                                  {errors.customerMobile}
                                </Form.Text>
                              )}
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Group as={Row}>
                            <Form.Label
                              column
                              sm={12}
                              className={`${styles.addPointsLabel}`}
                            >
                              Receipt value
                            </Form.Label>
                            <Col
                              sm={8}
                              className={`${styles.bgGrey} px-3 py-2`}
                            >
                              <Form.Control
                                type="number"
                                id="receiptValue"
                                className={`${styles.addpointInput}`}
                                name="receiptValue"
                                onKeyPress={(e) => {
                                  var char = String.fromCharCode(e.which);
                                  if (!/[0-9]/.test(char)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={handleChange}
                                value={values.receiptValue}
                              />
                            </Col>

                            <Col xs={3}>
                              <Form.Text
                                className={`${styles.receiptValueUnit}`}
                              >
                                EGP
                              </Form.Text>
                            </Col>
                            {errors.receiptValue && touched.receiptValue && (
                              <Form.Text className="text-error-green">
                                {errors.receiptValue}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col xs={12} md={8} className={`${styles.bgWhite} p-4 mb-3`}>
                    <h4>Order Number</h4>
                    <Container>
                      <Row>
                        <Col xs={12}>
                          <Form.Group as={Row}>
                            <Form.Label
                              column
                              sm={12}
                              className={`${styles.addPointsLabel} mb-3`}
                            >
                              Complete the transaction on your system, then
                              enter the order number here
                            </Form.Label>
                            <Col
                              xs={12}
                              md={6}
                              className={`${styles.bgGrey} px-3 py-2`}
                            >
                              <Form.Control
                                type="number"
                                id="orderNumber"
                                className={`${styles.addpointInput}`}
                                name="orderNumber"
                                onKeyPress={(e) => {
                                  var char = String.fromCharCode(e.which);
                                  if (!/[0-9]/.test(char)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={handleChange}
                                value={values.orderNumber}
                              />
                            </Col>
                            {errors.orderNumber && touched.orderNumber && (
                              <Form.Text className="text-error-green">
                                {errors.orderNumber}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col xs={12} md={4}>
                    <div
                      className={`${styles.addpointsBtnContent} d-flex align-items-start align-items-md-end`}
                    >
                      <Button
                        type="submit"
                        variant="success"
                        size="lg"
                        className={`${styles.addPointsBtn} w-100`}
                        disabled={isSubmitting}
                      >
                        Add Points
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
