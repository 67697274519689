import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { FaLock, FaMapMarkerAlt, FaMobile } from "react-icons/fa";
import { Formik } from "formik";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  cashierLogin,
  cleareResults,
} from "../../../redux/features/cashierSlice";
import failedMessage from "../../messages/Failed";
import successMessage from "../../messages/Successful";
import { useTranslation } from "react-i18next";
import ChangeLang from "../../changeLang/ChangeLang";
import { toast, Zoom } from "react-toastify";
import styles from "./Login.module.scss";
import { encrypt } from "n-krypta";

export default function Login() {
  const [branches, setBranches] = useState([]);
  const [merchantt, setMerchantt] = useState("");

  const merchantNamee = useParams().merchant;

  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  useEffect(() => {
    axios
      .get("https://hook.eu1.make.com/op9b65jo99zuj9lfs0eqxg7mg23ezw64", {
        params: {
          merchantName:
            merchantNamee === "greenCafe" ? "Green Cafe" : "Odonuts",
        },
      })
      .then((res) => {
        setBranches(res.data["Branch List"]);
      });

    dispatch(cleareResults());
  }, [merchantt]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cashierLoginValidation = yup.object().shape({
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(/^01[0125][0-9]{8}$/gm, "Phone number is not valid"),
    password: yup.string().required("Password is required"),
    branchID: yup.string().required("Branch name is required"),
  });

  const { t, i18n } = useTranslation();

  const key = "ShareeCoin_1234567";

  return (
    <div className={`${styles.loginContent} p-3`}>
      <ChangeLang />
      <h5 className="mb-4 pt-5 px-5">{t("WelcomeGreen")}</h5>
      <Container>
        <Row
          className={`${styles.loginCol} d-flex justify-content-center align-items-center`}
        >
          <Col xs={12} md={4} className="text-center">
            {merchantNamee === "greenCafe" && (
              <img
                src={require("../../../imgs/green-white-logo.png")}
                alt="green-logo"
                className={`${styles.greenLogo}`}
              />
            )}
            {merchantNamee === "odonuts" && (
              <img
                src={require("../../../imgs/3213.png")}
                alt="green-logo"
                className={`${styles.greenLogo}`}
              />
            )}
          </Col>
          <Col xs={12} md={{ span: 4, offset: 2 }}>
            <h5 className="text-center mb-3">{t("userLogin")}</h5>
            <Formik
              initialValues={{ phoneNumber: "", password: "", branchID: "" }}
              onSubmit={(values) => {
                const loadReq = toast.loading("Loading...", {
                  transition: Zoom,
                  position:
                    i18n.language === "ar" ? "bottom-right" : "bottom-left",
                });
                dispatch(
                  cashierLogin({
                    phoneNumber: values.phoneNumber,
                    password: values.password,
                    branchId: values.branchID,
                    merchantName: encrypt(merchantNamee, key),
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    navigate(`/${merchantNamee}/cashier/dashboard`);
                    successMessage(
                      res.payload.data.message,
                      res.payload.data["messageAr"],
                      loadReq
                    );
                  } else if (res.payload.data.statusCode === 201) {
                    navigate(`/${merchantNamee}/cashier/change-password`);
                    successMessage(
                      res.payload.data.error,
                      res.payload.data["errorAr"],
                      loadReq
                    );
                  } else {
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data["errorAr"],
                      loadReq
                    );
                  }
                });
              }}
              validationSchema={cashierLoginValidation}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} className="mb-3">
                      <InputGroup className={`${styles.loginInput} px-2`}>
                        <InputGroup.Text className={`${styles.loginInputIcon}`}>
                          <FaMobile />
                        </InputGroup.Text>
                        <Form.Control
                          type="tel"
                          placeholder={t("mobileNumber")}
                          maxLength={11}
                          className={`${styles.loginInputField}`}
                          id="phoneNumber"
                          name="phoneNumber"
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={handleChange}
                          value={values.phoneNumber}
                        />
                      </InputGroup>
                      {errors.phoneNumber && touched.phoneNumber && (
                        <Form.Text className="text-error-green">
                          {errors.phoneNumber}
                        </Form.Text>
                      )}
                    </Col>

                    <Col xs={12} className="mb-3">
                      <InputGroup className={`${styles.loginInput} px-2`}>
                        <InputGroup.Text className={`${styles.loginInputIcon}`}>
                          <FaLock />
                        </InputGroup.Text>
                        <Form.Control
                          type="password"
                          placeholder={t("password")}
                          maxLength={11}
                          className={`${styles.loginInputField}`}
                          id="password"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                      </InputGroup>
                      {errors.password && touched.password && (
                        <Form.Text className="text-error-green">
                          {errors.password}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12} className="mb-3">
                      <InputGroup
                        className={`${styles.loginInput} px-2 pt-2 pb-3`}
                      >
                        <InputGroup.Text className={`${styles.loginInputIcon}`}>
                          <FaMapMarkerAlt />
                        </InputGroup.Text>
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={12}
                            className={`${styles.branchLabel}`}
                          >
                            {t("selectBranch")}
                          </Form.Label>
                          <Col sm={12}>
                            <Form.Select
                              className={`${styles.branchInput}`}
                              name="branchID"
                              id="branchID"
                              onChange={(e) => {
                                setFieldValue("branchID", e.target.value);
                              }}
                              value={values.branchID}
                            >
                              <option value="">{t("select")}</option>
                              {branches.map((store, i) => {
                                return (
                                  <option key={i} value={store["BranchID"]}>
                                    {store["Branch Name Ar"]}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Col>
                        </Form.Group>
                      </InputGroup>
                      {errors.branchID && touched.branchID && (
                        <Form.Text className="text-error-green">
                          {errors.branchID}
                        </Form.Text>
                      )}
                    </Col>
                    <div className={`${styles.forgetPassowrdContent} mb-3`}>
                      <NavLink>{t("forgetPass")}</NavLink>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="danger"
                        type="submit"
                        size="sm"
                        className={`${styles.loginBtn} mt-1`}
                        disabled={isSubmitting}
                      >
                        {t("login")}
                      </Button>
                    </div>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <div className={`${styles.poweredLogoContent} px-5`}>
        <img
          src={require("../../../imgs/Powered by white.png")}
          alt="powered-logo"
        />
      </div>
    </div>
  );
}
