import React from "react";
import { Navigate, useParams } from "react-router-dom";

export default function MerchantPrivateRoute({ condition, children }) {
  const merchants = ["greenCafe", "odonuts"];

  const merchantName = useParams().merchant;

  if (merchants.includes(merchantName)) {
    return children;
  } else {
    return <Navigate to={`/404`} />;
  }
}
