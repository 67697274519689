import React from "react";
import { Navigate, useParams } from "react-router-dom";

export default function CashierPrivateRoute({ condition, children }) {
  console.log(useParams());

  const merchantName = useParams().merchant;

  if (condition === "" || condition === false) {
    return <Navigate to={`/${merchantName}/cashier/login`} />;
  } else {
    return children;
  }
}
