import React, { useEffect } from "react";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import styles from "./EditCashier.module.scss";
import { editCashier } from "../../../../../redux/features/adminSlice";
import { compare, decrypt } from "n-krypta";

export default function EditCashier(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const editCashierValidation = yup.object().shape({
    cashierFirstName: yup.string().required("Cashier name is required"),
    cashierLastName: yup.string().required("Cashier name is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(/^01[0125][0-9]{8}$/gm, "Phone number is not valid"),
    cphoneNumber: yup
      .string()
      .required("Confirm phone number is required")
      .oneOf([yup.ref("phoneNumber"), null], "Phone number must match"),
  });

  const token = useSelector((state) => state.green.admin.login.token);

  const key = "ShareeCoin_1234567";

  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.admin.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
    }
  }, [storedMerchant]);

  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.bgModal2}`}
      >
        <Modal.Body className={`${styles.bgModalGrey2}`}>
          <Container>
            <Row>
              <Col xs={12}>
                <h4>Edit Cashier Details</h4>
                <Formik
                  initialValues={{
                    cashierFirstName: props.firstname,
                    cashierLastName: props.lastname,
                    phoneNumber: props.mobilenumber,
                    cphoneNumber: "",
                  }}
                  onSubmit={(values) => {
                    const loadReq = toast.loading("Loading...", {
                      transition: Zoom,
                      position:
                        i18n.language === "ar" ? "bottom-right" : "bottom-left",
                    });
                    dispatch(
                      editCashier({
                        cashierID: props.cashierid,
                        firstName: values.cashierFirstName,
                        lastName: values.cashierLastName,
                        mobileNumber: values.phoneNumber,
                        branchID: props.branchid,
                        token,
                        merchantName : handleMerchant(decrypt(storedMerchant,key)),

                      })
                    ).then((res) => {
                      if (res.payload.data.statusCode === 200) {
                        successMessage(
                          res.payload.data.message,
                          res.payload.data["messageAr"],
                          loadReq
                        );
                        window.location.reload();
                      } else if (res.payload.data.statusCode === 401) {
                        failedMessage(
                          "Please, login again",
                          "سجل الدخول مرة اخري من فضلك",
                          loadReq
                        );

                        navigate(
                          `/${decrypt(storedMerchant, key)}/manager/login`
                        );
                      } else {
                        failedMessage(
                          res.payload.data.error,
                          res.payload.data["errorAr"],
                          loadReq
                        );
                      }
                    });
                  }}
                  validationSchema={editCashierValidation}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className={`${styles.bgWhite2} p-3 mt-3`}>
                          <Container>
                            <Form.Group as={Row} controlId="formGridState">
                              <Form.Label
                                column
                                sm={4}
                                className={`${styles.cashierInfo2}`}
                              >
                                Cashier Details:
                              </Form.Label>
                              <Col sm={8}>
                                <Form.Group as={Row}>
                                  <Col sm={6}>
                                    <Form.Control
                                      type="text"
                                      placeholder="First Name"
                                      className={`${styles.cashierInput2}`}
                                      name="cashierFirstName"
                                      id="cashierFirstName"
                                      onChange={handleChange}
                                      value={values.cashierFirstName}
                                    />
                                    {errors.cashierFirstName &&
                                      touched.cashierFirstName && (
                                        <Form.Text className="text-error">
                                          {errors.cashierFirstName}
                                        </Form.Text>
                                      )}
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Control
                                      type="text"
                                      placeholder="Last Name"
                                      className={`${styles.cashierInput2}`}
                                      name="cashierLastName"
                                      id="cashierLastName"
                                      onChange={handleChange}
                                      value={values.cashierLastName}
                                    />
                                    {errors.cashierLastName &&
                                      touched.cashierLastName && (
                                        <Form.Text className="text-error">
                                          {errors.cashierLastName}
                                        </Form.Text>
                                      )}
                                  </Col>
                                  <Col sm={12}>
                                    <Form.Control
                                      type="tel"
                                      maxLength={11}
                                      placeholder="Phone Number"
                                      className={`${styles.cashierInput2} mt-2`}
                                      name="phoneNumber"
                                      id="phoneNumber"
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        var char = String.fromCharCode(e.which);
                                        if (!/[0-9]/.test(char)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      value={values.phoneNumber}
                                    />
                                    {errors.phoneNumber &&
                                      touched.phoneNumber && (
                                        <Form.Text className="text-error">
                                          {errors.phoneNumber}
                                        </Form.Text>
                                      )}
                                  </Col>
                                  <Col sm={12}>
                                    <Form.Control
                                      type="tel"
                                      maxLength={11}
                                      placeholder="Confirm Phone Number"
                                      className={`${styles.cashierInput2} mt-2`}
                                      name="cphoneNumber"
                                      id="cphoneNumber"
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        var char = String.fromCharCode(e.which);
                                        if (!/[0-9]/.test(char)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      value={values.cphoneNumber}
                                    />
                                    {errors.cphoneNumber &&
                                      touched.cphoneNumber && (
                                        <Form.Text className="text-error">
                                          {errors.cphoneNumber}
                                        </Form.Text>
                                      )}
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Form.Group>
                          </Container>
                        </div>
                        <div className="d-grid mt-3">
                          <Button
                            variant="success"
                            className={`${styles.saveBtn2}`}
                            type="submit"
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
